export default {
  methods: {
    replacePlaceholders(
      text,
      workflow,
      client,
      revision,
      project,
      reviewLink,
      canUseHTML = false,
      language = "en"
    ) {
      let user = this.$auth.user;

      const emailSignature = this.$store.getters.emailSignature;
      if (emailSignature && canUseHTML)
        text = text.replace(/\[email signature\]/g, emailSignature);

      // Names and Emails
      const fullname = user.name + (user.surname ? " " + user.surname : "");
      text = text.replace(/\[user name\]/g, fullname);
      text = text.replace(/\[user email\]/g, user.email);
      if (client) {
        text = text.replace(/\[client name\]/g, client.name);
      }

      // Project related
      if (project) {
        text = text.replace(/\[project name\]/g, project?.name);

        text = text.replace(
          /\[allowed feedback days\]/g,
          project.days_allowed_for_client_feedback
        );

        let contact_name = "";
        if (project.contacts) {
          if (project.contacts.length > 1) {
            contact_name = "everyone";
          } else if (project.contacts.length === 1) {
            contact_name = project.contacts[0].firstname;
          }
        }
        text = text.replace(/\[contact name\]/g, contact_name);
      }

      // Find stage from given revision
      if (workflow && revision) {
        const stage = workflow.tasks.find((stage) => {
          let rev = stage.tasks.find((rev) => rev.id === revision.id);
          if (rev === undefined) {
            rev = stage.id === revision.id;
          }
          return !(rev === undefined || rev === false);
        });

        if (stage) {
          // Revisions and Stages
          if (stage.tasks.length) {
            const revIndex =
              stage?.tasks?.findIndex((rev) => rev.id === revision.id) ?? 0;
            const revPosition = revIndex + 1;

            if (revIndex !== -1) {
              const revsLeft = stage?.revisionsWithoutPreview.length - revIndex;

              text = text.replace(
                /\[revision number\]/g,
                this.convertNumberToWords(revPosition, language)
              );
              text = text.replace(
                /\[revision number #\]/g,
                revPosition.toString()
              );
              text = text.replace(
                /\[revision number st\]/g,
                this.convertNumberPositionToWords(revPosition, language)
              );

              text = text.replace(
                /\[stage revisions left\]/g,
                this.convertNumberToWords(revsLeft, language)
              );
              text = text.replace(
                /\[stage revisions left #\]/g,
                revsLeft?.toString()
              );
            }
            text = text.replace(
              /\[stage revisions count\]/g,
              this.convertNumberToWords(
                stage?.revisionsWithoutPreview.length,
                language
              )
            );
          }
          text = text.replace(/\[stage name\]/g, stage?.title);
        }
      }

      // Links
      // They can come with a redundant 'http' or 'https'
      text = text.replace(
        /(http(s)?:\/\/)?\[timeline( |%20)link\]/g,
        `${location.href}`
      );
      text = text.replace(/<div(.*?)>/g, "<p$1>").replace(/<\/div>/g, "</p>");

      return text;
    },
    convertNumberToWords(value, language = "en") {
      if (language === "en") return this.convertNumberToWordsEN(value);
      else if (language === "es") return this.convertNumberToWordsES(value);
      else if (language === "it") return this.convertNumberToWordsIT(value);
      else if (language === "fr") return this.convertNumberToWordsFR(value);

      return this.convertNumberToWordsEN(value);
    },
    convertNumberToWordsEN(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      var tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "zero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }

      if (numString.length === 2) {
        return tens[Number(numString[0])] + " " + ones[Number(numString[1])];
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0")
          return ones[Number(numString[0])] + " hundred";
        else
          return (
            ones[Number(numString[0])] +
            " hundred and " +
            this.convertNumberToWordsEN(+(numString[1] + numString[2]))
          );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) return ones[Number(numString[0])] + " thousand";
        if (end < 100)
          return (
            ones[Number(numString[0])] +
            " thousand and " +
            this.convertNumberToWordsEN(end)
          );
        return (
          ones[Number(numString[0])] +
          " thousand " +
          this.convertNumberToWordsEN(end)
        );
      }
      return "";
    },
    convertNumberToWordsES(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "uno",
        "dos",
        "tres",
        "cuatro",
        "cinco",
        "seis",
        "siete",
        "ocho",
        "nueve",
        "diez",
        "once",
        "doce",
        "trece",
        "catorce",
        "quince",
        "dieciséis",
        "diecisiete",
        "dieciocho",
        "diecinueve",
      ];
      var tens = [
        "",
        "",
        "veinti",
        "treinta",
        "cuarenta",
        "cincuenta",
        "sesenta",
        "setenta",
        "ochenta",
        "noventa",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "cero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }
      if (value === 20) {
        return "veinte";
      }
      if (value === 23) {
        return "veintitrés";
      }

      if (numString.length === 2) {
        if (value < 30) {
          return tens[Number(numString[0])] + ones[Number(numString[1])];
        } else {
          if (Number(numString[1]) === 0) {
            return tens[Number(numString[0])];
          }
          return (
            tens[Number(numString[0])] + " y " + ones[Number(numString[1])]
          );
        }
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "ciento";
          }
          return ones[Number(numString[0])] + " ciento";
        } else if (numString[0] === "1") {
          return (
            "ciento " +
            this.convertNumberToWordsES(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          " ciento " +
          this.convertNumberToWordsES(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) {
          if (numString[0] === "1") {
            return "mil";
          }
          return ones[Number(numString[0])] + " mil";
        }
        if (numString[0] === "1") {
          return "mil " + this.convertNumberToWordsES(end);
        }
        return (
          ones[Number(numString[0])] +
          " mil " +
          this.convertNumberToWordsES(end)
        );
      }
      return "";
    },
    convertNumberToWordsIT(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "uno",
        "due",
        "tre",
        "quattro",
        "cinque",
        "sei",
        "sette",
        "otto",
        "nove",
        "dieci",
        "undici",
        "dodici",
        "tredici",
        "quattordici",
        "quindici",
        "sedici",
        "diciassette",
        "diciotto",
        "diciannove",
      ];
      var tens = [
        "",
        "",
        "venti",
        "trenta",
        "quaranta",
        "cinquanta",
        "sessanta",
        "settanta",
        "ottanta",
        "novanta",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "zero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }

      if (numString.length === 2) {
        if (Number(numString[1]) === 0) {
          return tens[Number(numString[0])];
        }
        const onesPart = ones[Number(numString[1])];
        if (onesPart[0] === "u" || onesPart[0] === "o") {
          return tens[Number(numString[0])].slice(0, -1) + onesPart;
        }
        return tens[Number(numString[0])] + onesPart;
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "cento";
          }
          return ones[Number(numString[0])] + "cento";
        } else if (numString[0] === "1") {
          return (
            "cento" +
            this.convertNumberToWordsIT(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          "cento" +
          this.convertNumberToWordsIT(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) {
          if (numString[0] === "1") {
            return "mille";
          }
          return ones[Number(numString[0])] + "mila";
        }
        if (numString[0] === "1") {
          return "mille" + this.convertNumberToWordsIT(end);
        }
        return (
          ones[Number(numString[0])] + "mila" + this.convertNumberToWordsIT(end)
        );
      }
      return "";
    },
    convertNumberToWordsFR(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
        "dix",
        "onze",
        "douze",
        "treize",
        "quatorze",
        "quinze",
        "seize",
        "dix-sept",
        "dix-huit",
        "dix-neuf",
      ];
      var tens = [
        "",
        "",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingt",
        "quatre-vingt-dix",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "zéro";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }
      if (value === 80) {
        return "quatre-vingts";
      }

      if (numString.length === 2) {
        if (Number(numString[1]) === 0) {
          return tens[Number(numString[0])];
        }
        if (value > 70 && value < 80) {
          return tens[6] + "-" + ones[10 + Number(numString[1])];
        }
        if (value > 90 && value < 100) {
          return tens[8] + "-" + ones[10 + Number(numString[1])];
        }
        const onesPart = ones[Number(numString[1])];
        if (Number(numString[1]) === 1) {
          return tens[Number(numString[0])] + " et " + onesPart;
        }
        return tens[Number(numString[0])] + " " + onesPart;
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "cent";
          }
          return ones[Number(numString[0])] + " cent";
        } else if (numString[0] === "1") {
          return (
            "cent " +
            this.convertNumberToWordsFR(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          " cent " +
          this.convertNumberToWordsFR(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) {
          if (numString[0] === "1") {
            return "mille";
          }
          return ones[Number(numString[0])] + " mille";
        }
        if (numString[0] === "1") {
          return "mille " + this.convertNumberToWordsFR(end);
        }
        return (
          ones[Number(numString[0])] +
          " mille " +
          this.convertNumberToWordsFR(end)
        );
      }
      return "";
    },
    convertNumberPositionToWords(value, language = "en") {
      if (language === "en") return this.convertNumberPositionToWordsEN(value);
      else if (language === "es")
        return this.convertNumberPositionToWordsES(value);
      else if (language === "it")
        return this.convertNumberPositionToWordsIT(value);
      else if (language === "fr")
        return this.convertNumberPositionToWordsFR(value);

      return this.convertNumberPositionToWordsEN(value);
    },
    convertNumberPositionToWordsEN(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "first",
        "second",
        "third",
        "fourth",
        "fifth",
        "sixth",
        "seventh",
        "eighth",
        "ninth",
        "tenth",
        "eleventh",
        "twelfth",
        "thirteenth",
        "fourteenth",
        "fifteenth",
        "sixteenth",
        "seventeenth",
        "eighteenth",
        "nineteenth",
      ];
      var tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "zero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }

      if (numString.length === 2) {
        if (Number(numString[1]) === 0) {
          return tens[Number(numString[0])].replace(/ty$/, "tieth");
        } else {
          return tens[Number(numString[0])] + " " + ones[Number(numString[1])];
        }
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0")
          return ones[Number(numString[0])] + " hundred";
        else
          return (
            ones[Number(numString[0])] +
            " hundred and " +
            this.convertNumberToWordsEN(+(numString[1] + numString[2]))
          );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) return ones[Number(numString[0])] + " thousand";
        if (end < 100)
          return (
            ones[Number(numString[0])] +
            " thousand and " +
            this.convertNumberToWordsEN(end)
          );
        return (
          ones[Number(numString[0])] +
          " thousand " +
          this.convertNumberToWordsEN(end)
        );
      }
      return "";
    },
    convertNumberPositionToWordsES(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "primera",
        "segunda",
        "tercera",
        "cuatra",
        "quinta",
        "sexta",
        "séptima",
        "octava",
        "novena",
        "décima",
        "undécima",
        "duodécima",
        "decimotercera",
        "decimocuarta",
        "decimoquinta",
        "decimosexta",
        "decimoséptima",
        "decimoctava",
        "decimonovena",
      ];
      // var tens = [
      //   "",
      //   "",
      //   "veinte",
      //   "treinta",
      //   "cuarenta",
      //   "cincuenta",
      //   "sesenta",
      //   "setenta",
      //   "ochenta",
      //   "noventa",
      // ];
      var tenth = [
        "",
        "",
        "vigésima",
        "trigésima",
        "cuadragésima",
        "quincuagésima",
        "sexagésima",
        "septuagésima",
        "octogésima",
        "nonagésima",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "cero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }
      if (value === 20) {
        return "vigésima";
      }

      if (numString.length === 2) {
        if (value < 30) {
          return tenth[Number(numString[0])] + " " + ones[Number(numString[1])];
        } else {
          if (Number(numString[1]) === 0) {
            return tenth[Number(numString[0])];
          }
          return tenth[Number(numString[0])] + " " + ones[Number(numString[1])];
        }
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "ciento";
          }
          return ones[Number(numString[0])] + " ciento";
        } else if (numString[0] === "1") {
          return (
            "ciento " +
            this.convertNumberToWordsES(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          " ciento " +
          this.convertNumberToWordsES(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) return ones[Number(numString[0])] + " mil";
        if (end < 100)
          return (
            ones[Number(numString[0])] +
            " mil " +
            this.convertNumberToWordsES(end)
          );
        return (
          ones[Number(numString[0])] +
          " mil " +
          this.convertNumberToWordsES(end)
        );
      }
      return "";
    },
    convertNumberPositionToWordsIT(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "prima",
        "seconda",
        "terza",
        "quarta",
        "quinta",
        "sesta",
        "settima",
        "ottava",
        "nona",
        "decima",
        "undicesima",
        "dodicesima",
        "tredicesima",
        "quattordicesima",
        "quindicesima",
        "sedicesima",
        "diciassettesima",
        "diciottesima",
        "diciannovesima",
      ];
      var tens = [
        "",
        "",
        "venti",
        "trenta",
        "quaranta",
        "cinquanta",
        "sessanta",
        "settanta",
        "ottanta",
        "novanta",
      ];
      var tenth = [
        "",
        "",
        "ventesima",
        "trentesima",
        "quarantesima",
        "cinquantesima",
        "sessantesima",
        "settantestima",
        "ottantesima",
        "novantesima",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "cero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }
      if (value === 23) {
        return "ventitreesima";
      }

      if (numString.length === 2) {
        if (Number(numString[1]) === 0) {
          return tenth[Number(numString[0])];
        }

        let tensPart = tens[Number(numString[0])];
        let onesPart = this.convertNumberToWordsIT(Number(numString[1]));

        if (
          onesPart.endsWith("a") ||
          onesPart.endsWith("o") ||
          onesPart.endsWith("e")
        ) {
          onesPart = onesPart.slice(0, -1) + "esima";
        } else {
          onesPart += "esima";
        }

        if (onesPart[0] === "u" || onesPart[0] === "o") {
          return tensPart.slice(0, -1) + onesPart;
        }
        return tensPart + onesPart;
      }

      //100 and more
      if (numString.length === 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "cento";
          }
          return ones[Number(numString[0])] + " cento";
        } else if (numString[0] === "1") {
          return (
            "cento" +
            this.convertNumberToWordsIT(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          " cento" +
          this.convertNumberToWordsIT(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) return ones[Number(numString[0])] + " mila";
        if (end < 100)
          return (
            ones[Number(numString[0])] +
            " mille" +
            this.convertNumberToWordsIT(end)
          );
        return (
          ones[Number(numString[0])] +
          " mille" +
          this.convertNumberToWordsIT(end)
        );
      }
      return "";
    },
    convertNumberPositionToWordsFR(value) {
      value = Math.floor(value);
      var ones = [
        "",
        "première",
        "deuxième",
        "troisième",
        "quatrième",
        "cinquième",
        "sixième",
        "septième",
        "huitième",
        "neuvième",
        "dixième",
        "onzième",
        "douzième",
        "treizième",
        "quatorzième",
        "quinzième",
        "seizième",
        "dix-septième",
        "dix-huitième",
        "dix-neuvième",
      ];
      var tens = [
        "",
        "",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingt",
        "quatre-vingt-dix",
      ];
      var tenth = [
        "",
        "",
        "vingtième",
        "trentième",
        "quarantième",
        "cinquantième",
        "soixantième",
        "soixante-dixième",
        "quatre-vingtième",
        "quatre-vingt-dixième",
      ];

      var numString = value.toString();

      if (value < 0) throw new Error("Negative numbers are not supported.");

      if (value === 0) return "cero";

      //the case of 1 - 20
      if (value < 20) {
        return ones[value];
      }
      // if (value === 23) {
      //     return 'ventitreesima';
      // }

      if (numString.length === 2) {
        if (Number(numString[1]) === 0) {
          return tenth[Number(numString[0])];
        }

        let tensPart = tens[Number(numString[0])];
        let onesPart = this.convertNumberToWordsFR(Number(numString[1]));

        onesPart += "ième";
        if (Number(numString[1]) === 1) {
          return tensPart + " et " + onesPart;
        }
        return tensPart + " " + onesPart;
      }

      //100 and more
      if (numString.length == 3) {
        if (numString[1] === "0" && numString[2] === "0") {
          if (numString[0] === "1") {
            return "cent";
          }
          return ones[Number(numString[0])] + " cent";
        } else if (numString[0] === "1") {
          return (
            "cent " +
            this.convertNumberToWordsFR(+(numString[1] + numString[2]))
          );
        }
        return (
          ones[Number(numString[0])] +
          " cent" +
          this.convertNumberToWordsFR(+(numString[1] + numString[2]))
        );
      }

      if (numString.length === 4) {
        var end = +(numString[1] + numString[2] + numString[3]);
        if (end === 0) return ones[Number(numString[0])] + " mille";
        if (end < 100)
          return (
            ones[Number(numString[0])] +
            " mille" +
            this.convertNumberToWordsFR(end)
          );
        return (
          ones[Number(numString[0])] +
          " mille" +
          this.convertNumberToWordsFR(end)
        );
      }
      return "";
    },
    updateHtmlWithQuestion(dynamicQuestion) {
      const originalHtmlString = `<p style="font-size: 14px; font-weight: 500; cursor: text; margin: 0px; color: black; font-family: Montserrat; display: inline;">%QUESTION%</p><span style="display: inline; margin-left: 4px;"></span><a href="%SURVEY_LINK%" target="_blank" style="text-decoration: none; pointer-events: none; cursor: default;"><h1 style="color: #A9A7A7; cursor: pointer;">☆☆☆☆☆</h1></a>`;
      const questionPlaceholder = "%QUESTION%";
      const surveyLinkPlaceholder = "%SURVEY_LINK%";
      const currentLink = this.getFeedbackTabLink();
      const updatedHtmlString = originalHtmlString
        .replace(questionPlaceholder, dynamicQuestion)
        .replace(surveyLinkPlaceholder, currentLink);
      return updatedHtmlString;
    },
    getFeedbackTabLink() {
      const currentURL = window.location.href;
      // Check if the URL already contains '#feedback'
      if (!currentURL.includes("#feedback")) {
        // If '#feedback' is not present, add it to the URL
        const newURL = currentURL + "#feedback";
        // Use replaceState to update the URL without triggering a page reload
        window.history.replaceState({}, document.title, newURL);
        return newURL;
      }
      return currentURL;
    },
    getQuestionInEmail(survey) {
      const questionInEmail = survey?.surveyItems?.find(
        (item) =>
          item.is_question_in_email === true || item.is_question_in_email === 1
      );

      if (questionInEmail) {
        return questionInEmail.question;
      } else {
        return "";
      }
    },
    replaceMergeTags(text, mergeTags) {
      // Iterate through each merge tag and replace it with its value
      for (const [mergeTag, value] of Object.entries(mergeTags)) {
        const tagPattern = new RegExp(`\\[${mergeTag}\\]`, "g");
        if (mergeTag === "survey link") {
          text = text.replace(tagPattern, this.updateHtmlWithQuestion(value));
        } else if (mergeTag === "timeline link") {
          text = text.replace(
            /(http(s)?:\/\/)?\[timeline( |%20)link\]/g,
            value
          );
        } else if (mergeTag === "review link") {
          text = text.replace(
            /(http(s)?:\/\/)?\[review( |%20)link\]/g,
            value || `[review link]`
          );
        } else {
          text = text.replace(tagPattern, value);
        }
      }
      text = text.replace(/<div(.*?)>/g, "<p$1>").replace(/<\/div>/g, "</p>");
      return text;
    },
  },
};
